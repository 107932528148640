import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import { BarsOutlined } from "@ant-design/icons";
import { AccessControl } from "../accessControl/AccessControl";
import "./AdminHeader.css";
import { IBasicProps } from "../IBasicProps";
import { WEB_BASE_URL } from "../constants";

export const AdminHeader: FC<IBasicProps> = (props: IBasicProps) => {
	const location = useLocation();
	const isLargeScreen = useMediaQuery("(min-width: 1024px)");
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = (): void => {
		setIsOpen(!isOpen);
	};

	return (
		<AccessControl allowedPermission={["editor", "releaseAdmin", "superAdmin", "confirmationAdmin"]} {...props}>
			<>
				{isLargeScreen ? (
					<ul className="admin-menu">
						<li
							className={
								location.pathname.endsWith("contractapprovalmanager") ||
								location.pathname.endsWith("contractapprovalmanager/")
									? "active"
									: ""
							}
							onClick={() => props.navigate("/contractapprovalmanager")}
						>
							<span
								className={
									location.pathname.endsWith("contractapprovalmanager") ||
									location.pathname.endsWith("contractapprovalmanager/")
										? "active"
										: ""
								}
							>
								{props.t("menu.admin.homepage")}
							</span>
						</li>
						<li
							className={location.pathname.endsWith("contracts") ? "active" : ""}
							onClick={() => props.navigate("/contractapprovalmanager/contracts")}
						>
							<span className={location.pathname.endsWith("contracts") ? "active" : ""}>
								{props.t("menu.admin.contracts")}
							</span>
						</li>
						<AccessControl allowedPermission={["superAdmin", "confirmationAdmin", "releaseAdmin", "editor"]} {...props}>
							<li
								className={location.pathname.endsWith("open-applications") ? "active" : ""}
								onClick={() => props.navigate(`${WEB_BASE_URL}open-applications`)}
							>
								<span className={location.pathname.endsWith("open-applications") ? "active" : ""}>
									{props.t("menu.admin.open.contract")}
								</span>
							</li>
						</AccessControl>
					</ul>
				) : (
					<div className="relative ml-5">
						<button
							className="flex items-center justify-center w-12 h-12 rounded-md bg-gray-200 text-gray-700"
							onClick={toggleDropdown}
						>
							<BarsOutlined />
						</button>
						{isOpen && (
							<div className="absolute left-1/2 -translate-x-1/2 trans z-10 mt-2 py-2 w-fit bg-white rounded-md shadow-xl">
								<ul className="admin-menu-small">
									<li
										onClick={() => {
											props.navigate("/contractapprovalmanager");
											setIsOpen(false);
										}}
									>
										<span>{props.t("menu.admin.homepage")}</span>
									</li>
									<li
										onClick={() => {
											props.navigate("/contractapprovalmanager/contracts");
											setIsOpen(false);
										}}
									>
										<span>{props.t("menu.admin.contracts")}</span>
									</li>
									<AccessControl allowedPermission={["superAdmin", "confirmationAdmin"]} {...props}>
										<li
											onClick={() => {
												props.navigate(`${WEB_BASE_URL}open-applications`);
												setIsOpen(false);
											}}
										>
											<span>{props.t("menu.admin.open.contract")}</span>
										</li>
									</AccessControl>
								</ul>
							</div>
						)}
					</div>
				)}
			</>
		</AccessControl>
	);
};
