import { get } from "../../../security/secureClient";
import { ContractDetailsProjection } from "../model/ContractDetailsProjection";

export const getContractDetails = (
	id: string | undefined,
	employeeName: string | null
): Promise<ContractDetailsProjection> => {
	if (employeeName == null) {
		return get(`contract/${id}`);
	}
	return get(`contract/${id}?employeeName=${employeeName}`);
};

export const getContractDetailsForGivenUser = (
	contractId: string | undefined,
	userId: string | undefined
): Promise<ContractDetailsProjection> => get(`contract/${contractId}/user/${userId}`);
