import React, { FC, useEffect, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import moment from "moment/moment";
import { DatePicker, Spin, Switch } from "antd";
import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { AxiosError } from "axios";
import { RangePickerProps } from "antd/lib/date-picker";
import { Breadcrumbs } from "../breadcrumbs/Breadcrumbs";
import "react-quill/dist/quill.snow.css";
import "./ContractCreator.css";
import { ICategory } from "../common/model/ICategory";
import { getAllCategories } from "../contractList/contractListService";
import { getAllVisibilityGroups } from "../common/service/visibilityGroupService";
import { capitalize } from "../utils/CustomFunctions";
import { WEB_BASE_URL } from "../constants";
import { AccessControl } from "../accessControl/AccessControl";
import { getContractDetails } from "../contract/details/service/contractDetailsService";
import { IBasicProps } from "../IBasicProps";
import { fetchFile } from "../file/downloadFileService";
import { getCurrentUser } from "../security/securityService";
import { addNewContract, deleteContract, putContract } from "../common/service/contractService";
import { handleError, notifySuccess } from "../common/notificationService";
import { IVisibilityGroup } from "../common/model/IVisibilityGroup";
import { RequiredSign } from "../common/RequiredSign";
import { Loader } from "../common/Loader";
import { ContractCreatorAttachment, toContractCreatorAttachment } from "./model/ContractCreatorAttachment";
import { ContractCreatorCheckbox, toContractCreatorCheckboxList } from "./model/ContractCreatorCheckbox";
import { EmailProvider } from "./EmailProvider";
import { DocumentsUploader } from "./DocumentsUploader";
import { CheckboxProvider } from "./CheckboxProvider";
import { PreviewImagePicker } from "./PreviewImagePicker";
import { ContractCreatorButtons } from "./ContractCreatorButtons";
import { ChosenVisibilityGroups } from "./ChosenVisibilityGroups";
import { ContractCreatorReleaseStateLabel } from "./ContractCreatorReleaseStateLabel";
import { EmailReminder } from "./EmailReminder";

const { RangePicker } = DatePicker;
const DATE_FORMAT = "DD.MM.YYYY HH:mm";

export const ContractCreator: FC<IBasicProps> = (props: IBasicProps) => {
	const { contractId } = useParams();
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
	const [emailText, setEmailText] = useState("");
	const [infiniteInterval, setInfiniteInterval] = useState(false);
	const [interval, setInterval] = useState("");
	const [mandatoryDownload, setMandatoryDownload] = useState(false);
	const [allowRevocation, setAllowRevocation] = useState(false);
	const [allowCancellation, setAllowCancellation] = useState(false);
	const [cancellationMonths, setCancellationMonths] = useState("");
	const [cancellationDay, setCancellationDay] = useState("");
	const [revocationDeadline, setRevocationDeadline] = useState("");
	const [allowRecertification, setAllowRecertification] = useState(true);
	const [recertificationPeriod, setRecertificationPeriod] = useState(false);
	const [recertificationPeriodStart, setRecertificationPeriodStart] = useState<moment.Moment | null>(null);
	const [recertificationPeriodEnd, setRecertificationPeriodEnd] = useState<moment.Moment | null>(null);
	const [releaseNecessary, setReleaseNecessary] = useState(false);
	const [sendRegional, setSendRegional] = useState(false);
	const [previewImage, setPreviewImage] = useState<File | undefined>(undefined);
	const [checkboxes, setCheckboxes] = useState<ContractCreatorCheckbox[]>([]);
	const [attachments, setAttachments] = useState<ContractCreatorAttachment[]>([]);
	const [category, setCategory] = useState("");
	const [categories, setCategories] = useState<ICategory[]>([]);
	const [visibilityGroups, setVisibilityGroups] = useState<IVisibilityGroup[]>([]);
	const [chosenVisibilityGroups, setChosenVisibilityGroups] = useState<string[]>([]);
	const [releaseState, setReleaseState] = useState<string | undefined>(undefined);
	const [isQuestionnaireUploaded, setIsQuestionnaireUploaded] = useState(false);
	const [nameTooLongWarning, setNameTooLongWarning] = useState(false);
	const [loadingPreviewImage, setLoadingPreviewImage] = useState(false);
	const [creatingContractLoader, setCreatingContractLoader] = useState(false);
	const [emailAddressNotConfirmedWarning, setEmailAddressNotConfirmedWarning] = useState(false);
	const [singleEmail, setSingleEmail] = useState("");
	const [fetchingDataFailed, setFetchingDataFailed] = useState(false);
	const [attachmentsDownloaded, setAttachmentsDownloaded] = useState(false);
	const [firstRecertificationReminderDuration, setFirstRecertificationReminderDuration] = useState(4);
	const [firstRecertificationReminderEnabled, setFirstRecertificationReminderEnabled] = useState(false);
	const [secondRecertificationReminderDuration, setSecondRecertificationReminderDuration] = useState(3);
	const [secondRecertificationReminderEnabled, setSecondRecertificationReminderEnabled] = useState(false);
	const [expirationReminderDuration, setExpirationReminderDuration] = useState(0);
	const [expirationReminderEnabled, setExpirationReminderEnabled] = useState(false);
	const [afterExpirationReminderDuration, setAfterExpirationReminderDuration] = useState(2);
	const [afterExpirationReminderEnabled, setAfterExpirationReminderEnabled] = useState(false);
	const [allowMultipleConfirmations, setAllowMultipleConfirmations] = useState(false);
	if (!props.authenticated) {
		const parts = window.location.href.split(WEB_BASE_URL);
		return <Navigate to={`${WEB_BASE_URL}sso?redirect=${parts[1]}`} replace={true} />;
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useMemo(() => {
		getAllCategories()
			.then(r => setCategories(r as ICategory[]))
			.catch(error => console.log(error));
	}, []);

	useMemo(() => {
		getAllVisibilityGroups()
			.then(r => setVisibilityGroups(r as IVisibilityGroup[]))
			.catch(error => console.log(error));
	}, []);

	useEffect(() => {
		if (attachments.length > 0) {
			setAttachmentsDownloaded(true);
		}
	}, [attachments.length]);

	useMemo(() => {
		if (contractId) {
			getContractDetails(contractId, null)
				.then(r => {
					setFetchingDataFailed(false);
					setName(r.name);
					setDescription(r.description);
					setSendRegional(r.sendRegional);
					setEmailAddresses(r.emailAddresses);
					setEmailText(r.emailInfoText);
					if (r.interval) {
						setInterval(r.interval.toString());
					}
					setInfiniteInterval(r.infiniteInterval);
					setMandatoryDownload(r.mandatoryDownload);
					setAllowRevocation(r.allowRevocation);
					if (r.revocationDeadline) {
						setRevocationDeadline(r.revocationDeadline.toString());
					}
					setAllowCancellation(r.allowCancellation);
					setCancellationMonths(r.cancellationMonths);
					setCancellationDay(r.cancellationDay);
					setAllowRecertification(r.allowRecertification);
					setRecertificationPeriod(r.recertificationPeriod);
					setRecertificationPeriodStart(
						r.recertificationPeriodStart ? moment(r.recertificationPeriodStart, DATE_FORMAT) : null
					);
					setRecertificationPeriodEnd(
						r.recertificationPeriodEnd ? moment(r.recertificationPeriodEnd, DATE_FORMAT) : null
					);
					setCategory(r.category.key);
					setReleaseState(r.releaseState);
					setCheckboxes(toContractCreatorCheckboxList(r.checkboxes));
					setChosenVisibilityGroups(r.visibilityGroups);
					setReleaseNecessary(r.releaseNecessary);
					setIsQuestionnaireUploaded(r.isQuestionnaireUploaded);

					if (r.previewImage) {
						setLoadingPreviewImage(true);
						fetchFile(r.previewImage.id, r.previewImage.contentType)
							.then(response =>
								setPreviewImage(new File([response], r.previewImage.name, { type: r.previewImage.contentType }))
							)
							.catch(e => console.log(e))
							.finally(() => setLoadingPreviewImage(false));
					}

					if (r.attachments && r.attachments.length > 0) {
						setAttachments(toContractCreatorAttachment(r.attachments));
					} else {
						setAttachmentsDownloaded(true);
					}

					setFirstRecertificationReminderEnabled(r.firstRecertificationReminderEnabled);
					setFirstRecertificationReminderDuration(r.firstRecertificationReminderDuration);
					setSecondRecertificationReminderEnabled(r.secondRecertificationReminderEnabled);
					setSecondRecertificationReminderDuration(r.secondRecertificationReminderDuration);
					setExpirationReminderEnabled(r.expirationReminderEnabled);
					setExpirationReminderDuration(r.expirationReminderDuration);
					setAfterExpirationReminderEnabled(r.afterExpirationReminderEnabled);
					setAfterExpirationReminderDuration(r.afterExpirationReminderDuration);
					setAllowMultipleConfirmations(r.allowMultipleConfirmations);
				})
				.catch(error => {
					setFetchingDataFailed(true);
					console.log(error);
				});
		}
	}, []);

	const areAllEmailAddressesConfirmed = (): boolean => {
		if (singleEmail.length > 0) {
			setEmailAddressNotConfirmedWarning(true);
			return false;
		}

		return true;
	};

	const addContract = (createdContractReleaseState: string): void => {
		if (areAllEmailAddressesConfirmed()) {
			setCreatingContractLoader(true);
			addNewContract(
				createdContractReleaseState,
				name,
				description,
				interval,
				infiniteInterval,
				new Date(),
				chosenVisibilityGroups,
				{},
				attachments.map(att => att.value),
				mandatoryDownload,
				checkboxes.map(ch => ch.value),
				allowRevocation,
				revocationDeadline,
				allowCancellation,
				cancellationMonths,
				cancellationDay,
				allowRecertification,
				recertificationPeriod,
				recertificationPeriodStart?.format(DATE_FORMAT),
				recertificationPeriodEnd?.format(DATE_FORMAT),
				sendRegional,
				emailAddresses,
				emailText,
				category,
				releaseNecessary,
				firstRecertificationReminderDuration,
				firstRecertificationReminderEnabled,
				secondRecertificationReminderDuration,
				secondRecertificationReminderEnabled,
				expirationReminderDuration,
				expirationReminderEnabled,
				afterExpirationReminderDuration,
				afterExpirationReminderEnabled,
				allowMultipleConfirmations,
				previewImage
			)
				.then(() => {
					props.navigate(`${WEB_BASE_URL}contracts`);
					notifySuccess(props.t("contract.created.successfully"));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				})
				.finally(() => setCreatingContractLoader(false));
		}
	};

	const updateContract = (updatedContractReleaseState: string): void => {
		if (areAllEmailAddressesConfirmed()) {
			setCreatingContractLoader(true);
			if (contractId) {
				putContract(
					contractId,
					updatedContractReleaseState,
					name,
					description,
					interval,
					infiniteInterval,
					new Date(),
					chosenVisibilityGroups,
					attachments.map(att => att.value),
					mandatoryDownload,
					checkboxes.map(ch => ch.value),
					allowRevocation,
					revocationDeadline,
					allowCancellation,
					cancellationMonths,
					cancellationDay,
					allowRecertification,
					recertificationPeriod,
					recertificationPeriodStart?.format(DATE_FORMAT),
					recertificationPeriodEnd?.format(DATE_FORMAT),
					sendRegional,
					emailAddresses,
					emailText,
					category,
					releaseNecessary,
					firstRecertificationReminderDuration,
					firstRecertificationReminderEnabled,
					secondRecertificationReminderDuration,
					secondRecertificationReminderEnabled,
					expirationReminderDuration,
					expirationReminderEnabled,
					afterExpirationReminderDuration,
					afterExpirationReminderEnabled,
					allowMultipleConfirmations,
					previewImage
				)
					.then(() => {
						props.navigate(`${WEB_BASE_URL}contracts`);
						notifySuccess(props.t("contract.updated.successfully"));
					})
					.catch((error: Error | AxiosError) => {
						handleError(error);
					})
					.finally(() => setCreatingContractLoader(false));
			}
		}
	};

	const deleteDraftContract = (): void => {
		setCreatingContractLoader(true);
		if (contractId && releaseState === "DRAFT") {
			deleteContract(contractId)
				.then(() => {
					props.navigate(`${WEB_BASE_URL}contracts`);
					notifySuccess(props.t("contract.deleted.successfully"));
				})
				.catch((error: Error | AxiosError) => {
					handleError(error);
				})
				.finally(() => setCreatingContractLoader(false));
		}
	};

	const shouldBeDisabled = (): boolean => {
		const role = getCurrentUser()?.role as string;

		return !(role === "editor" || role === "superAdmin") || (releaseState !== "DRAFT" && releaseState !== undefined);
	};

	const shouldBeDisabledForReleasedEditable = (): boolean => {
		const role = getCurrentUser()?.role as string;

		return (
			shouldBeDisabled() &&
			!(
				(role === "editor" || role === "superAdmin") &&
				(releaseState === "RELEASED" || releaseState === "INACTIVE_DEADLINE")
			)
		);
	};

	const addVisibilityGroup = (vg: string): void => {
		if (!chosenVisibilityGroups.includes(vg.trim())) {
			const newArr = [...chosenVisibilityGroups, vg.trim()];
			setChosenVisibilityGroups(newArr);
		}
	};

	useEffect(() => {
		if (name.length <= 255) {
			setNameTooLongWarning(false);
		} else {
			setNameTooLongWarning(true);
		}
	}, [name]);

	const downloadFile = (file: File | undefined): void => {
		if (file) {
			const link = document.createElement("a");
			link.href = URL.createObjectURL(file);
			link.setAttribute("download", file.name);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const downloadAllFiles = (): void => {
		attachments.map(attachment => {
			downloadFile(attachment.value);
		});
	};

	return (
		<AccessControl
			allowedPermission={["superAdmin", "editor", "regionalManager", "releaseAdmin", "confirmationAdmin"]}
			{...props}
		>
			{fetchingDataFailed ? (
				<div className="flex flex-col items-center pt-20 gap-4">
					<span className="text-xl text-center">{props.t("contract.not.found")}</span>
					<button className="primary h-8" onClick={() => props.navigate(`${WEB_BASE_URL}`)}>
						{props.t("back.to.homepage")}
					</button>
				</div>
			) : (
				<div className="contract-creator-main-container">
					<div className="pl-8 pr-8 flex justify-between w-full">
						<Breadcrumbs {...props} contractId={contractId} isAdmin={true} />
						{releaseState && (
							<ContractCreatorReleaseStateLabel releaseState={releaseState} contractId={contractId} {...props} />
						)}
					</div>
					<div className="contract-creator-form-container">
						<div className="w-1/4 pl-16">
							{loadingPreviewImage ? (
								<div className="flex items-center justify-center w-64 mt-20">
									<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
								</div>
							) : (
								<PreviewImagePicker
									previewImage={previewImage}
									setPreviewImage={setPreviewImage}
									disabled={shouldBeDisabledForReleasedEditable()}
									{...props}
								/>
							)}
						</div>
						<div className="contract-creator-form-data-container">
							<div className="w-4/5">
								<label>
									<div className="flex">
										{props.t("name")} <RequiredSign />
									</div>
									<input
										className="w-full"
										type="text"
										name="Name"
										value={name}
										onChange={e => setName(e.target.value)}
										disabled={shouldBeDisabledForReleasedEditable()}
									/>
									<div className="h-3 mb-1">
										{nameTooLongWarning && (
											<div className="text-xs text-red ml-1">{props.t("name.too.long.warning")}</div>
										)}
									</div>
								</label>

								<div>
									<div className="mb-1 flex">
										{props.t("description")} <RequiredSign />
									</div>
									<ReactQuill
										theme="snow"
										value={description}
										onChange={setDescription}
										readOnly={shouldBeDisabledForReleasedEditable()}
									/>
								</div>
								<div className="switch-button-container">
									<span>{props.t("infinite.interval.contract")}</span>
									<Switch
										className="w-4"
										checkedChildren={<CheckOutlined />}
										unCheckedChildren={<CloseOutlined />}
										checked={infiniteInterval}
										onChange={() => setInfiniteInterval(!infiniteInterval)}
										disabled={shouldBeDisabled()}
									/>
								</div>
								{!infiniteInterval && (
									<label>
										<div className="flex">
											{props.t("interval.in.month")}
											<RequiredSign />
										</div>
										<select value={interval} onChange={e => setInterval(e.target.value)} disabled={shouldBeDisabled()}>
											<option value="" selected disabled hidden>
												{props.t("choose.interval")}
											</option>
											{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value, index) => (
												<option key={`con-cre-interval-${index + 1}`}>{value}</option>
											))}
										</select>
									</label>
								)}
								<label>
									<div className="flex">
										{props.t("category")}
										<RequiredSign />
									</div>
									<select value={category} onChange={e => setCategory(e.target.value)} disabled={shouldBeDisabled()}>
										<option value="" selected disabled hidden>
											{props.t("choose.category")}
										</option>
										{categories.map((c, i) => (
											<option value={c.key} key={i}>
												{capitalize(c.value)}
											</option>
										))}
									</select>
								</label>
								<label>
									<div>{props.t("visibility.group")}</div>
									<select
										onChange={e => addVisibilityGroup(e.target.value)}
										disabled={shouldBeDisabled()}
										defaultValue={props.t("choose.visibility.group")}
										value={props.t("choose.visibility.group")}
									>
										<option selected disabled hidden>
											{props.t("choose.visibility.group")}
										</option>
										{visibilityGroups.map((v, i) => (
											<option value={v.key} key={i}>
												{v.name}
											</option>
										))}
									</select>
								</label>
								<ChosenVisibilityGroups
									visibilityGroups={visibilityGroups}
									chosenVisibilityGroups={chosenVisibilityGroups}
									setVisibilityGroups={setChosenVisibilityGroups}
									disabled={shouldBeDisabled()}
									{...props}
								/>
								<DocumentsUploader
									attachments={attachments}
									setAttachments={setAttachments}
									disabled={shouldBeDisabled()}
									downloadAllFiles={downloadAllFiles}
									areDocumentsDownloadedFromServer={attachmentsDownloaded}
									{...props}
								/>
								<div className="flex flex-col">
									<div className="switch-button-container">
										<span>{props.t("mandatory.download")}</span>
										<Switch
											className="w-4"
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={mandatoryDownload}
											onChange={() => setMandatoryDownload(!mandatoryDownload)}
											disabled={shouldBeDisabled()}
										/>
									</div>
									<CheckboxProvider
										checkboxes={checkboxes}
										setCheckboxes={setCheckboxes}
										disabled={shouldBeDisabled()}
										{...props}
									/>
									<div className="switch-button-container">
										<span>{props.t("multiple.confirmation")}</span>
										<Switch
											className="w-4"
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={allowMultipleConfirmations}
											onChange={() => setAllowMultipleConfirmations(!allowMultipleConfirmations)}
											disabled={shouldBeDisabled()}
										/>
									</div>
									<div className="switch-button-container">
										<span>{props.t("revocation.possible")}</span>
										<Switch
											className="w-4"
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={allowRevocation}
											onChange={() => setAllowRevocation(!allowRevocation)}
											disabled={shouldBeDisabled()}
										/>
									</div>
									{allowRevocation && (
										<div className="flex">
											<input
												className="w-full m-0 rounded-none rounded-l-lg"
												type="text"
												name="revocationTime"
												value={revocationDeadline}
												onChange={e => setRevocationDeadline(e.target.value)}
												disabled={shouldBeDisabled()}
											/>
											<label className="w-fit px-1 border rounded-r-lg font-semibold bg-gray-300 text-gray-500 flex items-center justify-center">
												<span>{props.t("days")}</span>
											</label>
										</div>
									)}
									<div className="switch-button-container">
										<span>{props.t("cancellation.possible")}</span>
										<Switch
											className="w-4"
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={allowCancellation}
											onChange={() => setAllowCancellation(!allowCancellation)}
											disabled={shouldBeDisabled()}
										/>
									</div>
									{allowCancellation && (
										<div className="flex">
											<input
												className="w-full m-0 rounded-none rounded-l-lg"
												type="text"
												name="cancellationMonths"
												value={cancellationMonths}
												onChange={e => setCancellationMonths(e.target.value)}
												disabled={shouldBeDisabled()}
											/>
											<label className="w-fit px-1 border rounded-r-lg font-semibold bg-gray-300 text-gray-500 flex items-center justify-center mr-4">
												<span>{props.t("months")}</span>
											</label>
											<input
												className="w-full m-0 rounded-none rounded-l-lg"
												type="text"
												name="cancellationDay"
												value={cancellationDay}
												onChange={e => setCancellationDay(e.target.value)}
												disabled={shouldBeDisabled()}
											/>
											<label className="w-fit px-1 border rounded-r-lg font-semibold bg-gray-300 text-gray-500 flex items-center justify-center">
												<span>{props.t("cancellation.day.of.month")}</span>
											</label>
										</div>
									)}
									<div className="switch-button-container">
										<span>{props.t("recertification.possible")}</span>
										<Switch
											className="w-4"
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={allowRecertification}
											onChange={() => setAllowRecertification(!allowRecertification)}
											disabled={shouldBeDisabled()}
										/>
									</div>
									{allowRecertification && (
										<div className="flex flex-col xl:flex-row gap-4">
											<div className="switch-button-container">
												<span>{props.t("recertification.period")}</span>
												<Switch
													className="w-4"
													checkedChildren={<CheckOutlined />}
													unCheckedChildren={<CloseOutlined />}
													checked={recertificationPeriod}
													onChange={() => setRecertificationPeriod(!recertificationPeriod)}
													disabled={shouldBeDisabledForReleasedEditable()}
												/>
											</div>
											<div>
												<RangePicker
													placeholder={[props.t("recertification.from"), props.t("recertification.to")]}
													className="rounded"
													size="small"
													disabled={!recertificationPeriod || shouldBeDisabledForReleasedEditable()}
													format="DD.MM.YYYY HH:mm"
													showTime={{
														format: "HH:mm",
													}}
													onChange={(value: RangePickerProps["value"]) => {
														if (value?.length === 2) {
															setRecertificationPeriodStart(value[0] as moment.Moment);
															setRecertificationPeriodEnd(value[1]);
														} else {
															setRecertificationPeriodStart(null);
															setRecertificationPeriodEnd(null);
														}
													}}
													value={[recertificationPeriodStart, recertificationPeriodEnd]}
												/>
											</div>
										</div>
									)}
									<div className="switch-button-container">
										<span>{props.t("release.necessary")}</span>
										<Switch
											className="w-4"
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={releaseNecessary}
											onChange={() => setReleaseNecessary(!releaseNecessary)}
											disabled={shouldBeDisabled()}
										/>
									</div>
									<EmailProvider
										emailAddresses={emailAddresses}
										setEmailAddresses={setEmailAddresses}
										emailText={emailText}
										setEmailText={setEmailText}
										disabled={shouldBeDisabled()}
										singleEmail={singleEmail}
										setSingleEmail={setSingleEmail}
										isEmailAddressNotConfirmed={emailAddressNotConfirmedWarning}
										setIsEmailAddressNotConfirmed={setEmailAddressNotConfirmedWarning}
										{...props}
									/>
									<div className="switch-button-container">
										<span>{props.t("email.confirmation")}</span>
										<Switch
											className="w-4"
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={sendRegional}
											onChange={() => setSendRegional(!sendRegional)}
											disabled={shouldBeDisabled()}
										/>
									</div>
									<EmailReminder
										reminderLabel="reminder.firstRecertificationReminder"
										reminderUnitLabel="reminder.input"
										reminderEnabled={firstRecertificationReminderEnabled}
										reminderDuration={firstRecertificationReminderDuration}
										setReminderEnabled={setFirstRecertificationReminderEnabled}
										setReminderDuration={setFirstRecertificationReminderDuration}
										disabled={shouldBeDisabled()}
										{...props}
									/>
									<EmailReminder
										reminderLabel="reminder.secondRecertificationReminder"
										reminderUnitLabel="reminder.input"
										reminderEnabled={secondRecertificationReminderEnabled}
										reminderDuration={secondRecertificationReminderDuration}
										setReminderEnabled={setSecondRecertificationReminderEnabled}
										setReminderDuration={setSecondRecertificationReminderDuration}
										disabled={shouldBeDisabled()}
										{...props}
									/>
									<EmailReminder
										reminderLabel="reminder.expiration"
										reminderUnitLabel="reminder.input"
										reminderEnabled={expirationReminderEnabled}
										reminderDuration={expirationReminderDuration}
										setReminderEnabled={setExpirationReminderEnabled}
										setReminderDuration={setExpirationReminderDuration}
										disabled={shouldBeDisabled()}
										{...props}
									/>
									<EmailReminder
										reminderLabel="reminder.afterExpiration"
										reminderUnitLabel="reminder.input"
										reminderEnabled={afterExpirationReminderEnabled}
										reminderDuration={afterExpirationReminderDuration}
										setReminderEnabled={setAfterExpirationReminderEnabled}
										setReminderDuration={setAfterExpirationReminderDuration}
										disabled={shouldBeDisabled()}
										{...props}
									/>
								</div>
							</div>
						</div>
					</div>
					<ContractCreatorButtons
						contractId={contractId}
						releaseState={releaseState}
						addContract={addContract}
						updateContract={updateContract}
						deleteContract={deleteDraftContract}
						isQuestionnaireUploaded={isQuestionnaireUploaded}
						{...props}
					/>
				</div>
			)}
			{creatingContractLoader && <Loader size={64} coverBackground={true} />}
		</AccessControl>
	);
};
