import axios, { AxiosRequestHeaders } from "axios";
import { API_BASE_URL } from "../../constants";
import { authHeader, get, post } from "../../security/secureClient";
import { GeneratePdfRequest } from "../../contract/details/model/GeneratePdfRequest";
import { IQuestionnaire } from "../model/IQuestionnaire";
import { PdfOptions } from "../../contract/details/model/PdfOptions";

export const addNewContract = (
	releaseState: string,
	name: string,
	description: string,
	interval: string,
	infiniteInterval: boolean,
	expirationDate: Date,
	visibilityGroups: string[],
	questionnaire: unknown,
	attachments: File[],
	mandatoryDownload: boolean,
	checkboxes: string[],
	allowRevocation: boolean,
	revocationDeadline: string,
	allowCancellation: boolean,
	cancellationMonths: string,
	cancellationDay: string,
	allowRecertification: boolean,
	recertificationPeriod: boolean,
	recertificationPeriodStart: string | undefined,
	recertificationPeriodEnd: string | undefined,
	sendRegional: boolean,
	emailAddresses: string[],
	emailInfoText: string,
	category: string,
	releaseNecessary: boolean,
	firstRecertificationReminderDuration: number,
	firstRecertificationReminderEnabled: boolean,
	secondRecertificationReminderDuration: number,
	secondRecertificationReminderEnabled: boolean,
	expirationReminderDuration: number,
	expirationReminderEnabled: boolean,
	afterExpirationReminderDuration: number,
	afterExpirationReminderEnabled: boolean,
	allowMultipleConfirmations: boolean,
	previewImage?: File
): Promise<any> => {
	const formData = new FormData();
	formData.append("releaseState", releaseState);
	formData.append("name", name);
	formData.append("description", description);
	formData.append("interval", interval);
	formData.append("infiniteInterval", JSON.stringify(infiniteInterval));
	formData.append("expirationDate", JSON.stringify(expirationDate));
	formData.append("visibilityGroups", visibilityGroups.join(","));
	formData.append("mandatoryDownload", JSON.stringify(mandatoryDownload));
	formData.append("checkboxes", JSON.stringify(checkboxes));
	formData.append("allowRevocation", JSON.stringify(allowRevocation));
	formData.append("recertificationPeriod", JSON.stringify(recertificationPeriod));
	formData.append("recertificationPeriodStart", recertificationPeriodStart || "");
	formData.append("recertificationPeriodEnd", recertificationPeriodEnd || "");
	formData.append("revocationDeadline", revocationDeadline);
	formData.append("allowCancellation", JSON.stringify(allowCancellation));
	formData.append("cancellationMonths", cancellationMonths);
	formData.append("cancellationDay", cancellationDay);
	formData.append("allowRecertification", JSON.stringify(allowRecertification));
	formData.append("sendRegional", JSON.stringify(sendRegional));
	formData.append("emailAddresses", emailAddresses.join(","));
	formData.append("emailInfoText", emailInfoText);
	formData.append("category", category);
	formData.append("releaseNecessary", JSON.stringify(releaseNecessary));
	formData.append("firstRecertificationReminderDuration", JSON.stringify(firstRecertificationReminderDuration));
	formData.append("firstRecertificationReminderEnabled", JSON.stringify(firstRecertificationReminderEnabled));
	formData.append("secondRecertificationReminderDuration", JSON.stringify(secondRecertificationReminderDuration));
	formData.append("secondRecertificationReminderEnabled", JSON.stringify(secondRecertificationReminderEnabled));
	formData.append("expirationReminderDuration", JSON.stringify(expirationReminderDuration));
	formData.append("expirationReminderEnabled", JSON.stringify(expirationReminderEnabled));
	formData.append("afterExpirationReminderDuration", JSON.stringify(afterExpirationReminderDuration));
	formData.append("afterExpirationReminderEnabled", JSON.stringify(afterExpirationReminderEnabled));
	formData.append("allowMultipleConfirmations", JSON.stringify(allowMultipleConfirmations));
	if (previewImage !== undefined) {
		formData.append("previewImage", previewImage);
	} else {
		formData.append("previewImage", JSON.stringify(null));
	}

	if (attachments.length > 0) {
		attachments.forEach(att => {
			formData.append("attachments", att);
		});
	}

	return post("contract", formData, {});
};

export const putContract = (
	contractId: string,
	releaseState: string,
	name: string,
	description: string,
	interval: string,
	infiniteInterval: boolean,
	expirationDate: Date,
	visibilityGroups: string[],
	attachments: File[],
	mandatoryDownload: boolean,
	checkboxes: string[],
	allowRevocation: boolean,
	revocationDeadline: string,
	allowCancellation: boolean,
	cancellationMonths: string,
	cancellationDay: string,
	allowRecertification: boolean,
	recertificationPeriod: boolean,
	recertificationPeriodStart: string | undefined,
	recertificationPeriodEnd: string | undefined,
	sendRegional: boolean,
	emailAddresses: string[],
	emailInfoText: string,
	category: string,
	releaseNecessary: boolean,
	firstRecertificationReminderDuration: number,
	firstRecertificationReminderEnabled: boolean,
	secondRecertificationReminderDuration: number,
	secondRecertificationReminderEnabled: boolean,
	expirationReminderDuration: number,
	expirationReminderEnabled: boolean,
	afterExpirationReminderDuration: number,
	afterExpirationReminderEnabled: boolean,
	allowMultipleConfirmations: boolean,
	previewImage?: File
): Promise<any> => {
	const formData = new FormData();
	formData.append("contractId", contractId);
	formData.append("releaseState", releaseState);
	formData.append("name", name);
	formData.append("description", description);
	formData.append("interval", interval);
	formData.append("infiniteInterval", JSON.stringify(infiniteInterval));
	formData.append("expirationDate", JSON.stringify(expirationDate));
	formData.append("visibilityGroups", visibilityGroups.join(","));
	formData.append("mandatoryDownload", JSON.stringify(mandatoryDownload));
	formData.append("checkboxes", JSON.stringify(checkboxes));
	formData.append("allowRevocation", JSON.stringify(allowRevocation));
	formData.append("revocationDeadline", revocationDeadline);
	formData.append("allowCancellation", JSON.stringify(allowCancellation));
	formData.append("cancellationMonths", cancellationMonths);
	formData.append("cancellationDay", cancellationDay);
	formData.append("allowRecertification", JSON.stringify(allowRecertification));
	formData.append("recertificationPeriod", JSON.stringify(recertificationPeriod));
	formData.append("recertificationPeriodStart", recertificationPeriodStart || "");
	formData.append("recertificationPeriodEnd", recertificationPeriodEnd || "");
	formData.append("sendRegional", JSON.stringify(sendRegional));
	formData.append("emailAddresses", emailAddresses.join(","));
	formData.append("emailInfoText", emailInfoText);
	formData.append("category", category);
	formData.append("releaseNecessary", JSON.stringify(releaseNecessary));
	formData.append("firstRecertificationReminderDuration", JSON.stringify(firstRecertificationReminderDuration));
	formData.append("firstRecertificationReminderEnabled", JSON.stringify(firstRecertificationReminderEnabled));
	formData.append("secondRecertificationReminderDuration", JSON.stringify(secondRecertificationReminderDuration));
	formData.append("secondRecertificationReminderEnabled", JSON.stringify(secondRecertificationReminderEnabled));
	formData.append("expirationReminderDuration", JSON.stringify(expirationReminderDuration));
	formData.append("expirationReminderEnabled", JSON.stringify(expirationReminderEnabled));
	formData.append("afterExpirationReminderDuration", JSON.stringify(afterExpirationReminderDuration));
	formData.append("afterExpirationReminderEnabled", JSON.stringify(afterExpirationReminderEnabled));
	formData.append("allowMultipleConfirmations", JSON.stringify(allowMultipleConfirmations));

	if (previewImage !== undefined) {
		formData.append("previewImage", previewImage);
	} else {
		formData.append("previewImage", JSON.stringify(null));
	}

	if (attachments.length > 0) {
		attachments.forEach(att => {
			formData.append("attachments", att);
		});
	}

	return post("contract/put", formData, {});
};

export const deleteContract = (contractId: string): Promise<any> =>
	axios.request({
		url: `${API_BASE_URL}contract/${contractId}`,
		method: "DELETE",
		headers: authHeader() as AxiosRequestHeaders,
	});

export const sendApproveContract = (contractId: string): Promise<any> => post(`contract/${contractId}/approve`, {}, {});

export const sendApproveApproval = (contractId: string, affectedUserId: string): Promise<any> =>
	post(`contract/${contractId}/approve-approval`, { affectedUserId }, {});

export const sendRejectContract = (contractId: string): Promise<any> => post(`contract/${contractId}/reject`, {}, {});

export const sendRejectApproval = (contractId: string, reason: string, affectedUserId: string): Promise<any> =>
	post(`contract/${contractId}/reject-approval`, { reason, affectedUserId }, {});

export const sendReleaseContract = (contractId: string): Promise<any> => post(`contract/${contractId}/release`, {}, {});

export const sendReleaseContractWithNotification = (contractId: string): Promise<any> =>
	post(`contract/${contractId}/release/with-notification`, {}, {});

export const sendRevokeContract = (contractId: string): Promise<any> => post(`contract/${contractId}/revoke`, {}, {});
export const sendCancelContract = (contractId: string): Promise<any> => post(`contract/${contractId}/cancel`, {}, {});
export const checkIfContractCanBeRecertified = (contractId: string): Promise<any> =>
	post(`contract/${contractId}/check-recertify`, {}, {});
export const sendRecertifyContract = (
	contractId: string,
	isApprovalOfRecertify: boolean,
	employeeName: string | null
): Promise<any> => post(`contract/${contractId}/recertify`, { isApprovalOfRecertify, employeeName }, {});

export const sendRecertifyCancel = (contractId: string): Promise<any> =>
	post(`contract/${contractId}/cancel-recertify`, {}, {});

export const getEventNameBasedOnReleaseState = (releaseState: string): string => {
	switch (releaseState) {
		case "COMMISSIONED":
			return "commissioned";
		case "DRAFT":
			return "createDraft";
		case "INACTIVE":
			return "setInactive";
		case "INVALID":
			return "setInvalid";
		case "TO_BE_RELEASED":
			return "sendToRelease";
		case "RELEASED":
			return "release";
		default:
			return "unknown";
	}
};

export const getLatestEventForType = (contractId: string, type: string): Promise<any> =>
	get(`contract/${contractId}/latest/${type}`);

export const getLatestEventForAffectedUser = (contractId: string, affectedUserId: string): Promise<any> =>
	get(`contract/${contractId}/user/${affectedUserId}/event`);

export const getQuestionnaire = (contractId: string, employeeName?: string | null): Promise<IQuestionnaire> => {
	console.log("employeeName", employeeName);
	if (employeeName != null) {
		return get(`contract/${contractId}/questionnaire?employeeName=${employeeName}`);
	}
	return get(`contract/${contractId}/questionnaire`);
};

export const generatePdf = (
	contractId: string | undefined,
	generatePdfRequest: GeneratePdfRequest,
	pdfOptions: PdfOptions,
	employeeName: string | null
): Promise<any> => {
	if (employeeName != null) {
		return post(
			`contract/${contractId}/questionnaire/generate-pdf?employeeName=${employeeName}`,
			{ requestData: generatePdfRequest, options: pdfOptions },
			{}
		);
	}
	return post(
		`contract/${contractId}/questionnaire/generate-pdf`,
		{ requestData: generatePdfRequest, options: pdfOptions },
		{}
	);
};
