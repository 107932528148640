import axios, { AxiosError, AxiosResponse } from "axios";
import { notification } from "antd";
import { t } from "i18next";
import { IErrorResponse } from "./model/IErrorResponse";

const notifyErrorWithMessage = (response: AxiosResponse): void => {
	let errorResponse = response.data as IErrorResponse;

	if (response.data instanceof Blob) {
		response.data
			.text()
			.then(text => {
				errorResponse = JSON.parse(text) as IErrorResponse;
				if (errorResponse.code === "employees.or.locations.were.change") {
					notification.error({
						message: t("employees.or.locations.were.change"),
						description: errorResponse.message,
						placement: "top",
					});
				} else {
					notification.error({
						message: t("something.went.wrong"),
						description: errorResponse.message,
						placement: "top",
					});
				}
			})
			.catch(e => {
				console.error(e);
			});
	} else {
		if (errorResponse.code === "employees.were.changed") {
			notification.error({
				message: t("something.went.wrong"),
				description: t("employees.were.changed"),
				placement: "top",
			});
		} else if (errorResponse.code === "locations.were.changed") {
			notification.error({
				message: t("something.went.wrong"),
				description: t("locations.were.changed"),
				placement: "top",
			});
		} else {
			notification.error({
				message: t("something.went.wrong"),
				description: errorResponse.message,
				placement: "top",
			});
		}
	}
};

export const handleError = (error: Error | AxiosError): void => {
	if (axios.isAxiosError(error) && error.response && error.response.status === 400) {
		notifyErrorWithMessage(error.response);
	} else {
		notification.error({
			message: t("something.went.wrong"),
			placement: "top",
		});
	}
};

export const notifySuccess = (message: string): void => {
	notification.success({
		message,
		placement: "top",
	});
};

export const notifyError = (message: string): void => {
	notification.error({
		message,
		placement: "top",
	});
};

export const notifyInfo = (message: string): void => {
	notification.info({
		message,
		placement: "top",
	});
};
