import React, { FC } from "react";
import { getFormattedDate } from "../utils/CustomFunctions";
import { ReactComponent as ClockIcon } from "../images/clock.svg";
import { ReactComponent as FileIcon } from "../images/file.svg";
import { ReactComponent as InactiveIcon } from "../images/inactive.svg";
import { ReactComponent as InvalidIcon } from "../images/invalid.svg";
import { ReactComponent as TickIcon } from "../images/tick.svg";
import { ReactComponent as UserIcon } from "../images/user.svg";
import { ReactComponent as RepeatIcon } from "../images/repeat.svg";
import { IBasicProps } from "../IBasicProps";
import { AntdIcon } from "../common/AntdIcon";
import { IContractUIProps } from "./IContractUIProps";
import { ContractState } from "./Contract";
import "./ContractLabels.css";

interface IContractLabelProps extends IBasicProps {
	releaseState?: string;
	contractState?: ContractState;
	expirationDate?: Date;
	infiniteInterval?: boolean;
}

export const ContractReleaseStateLabel: FC<IContractLabelProps> = (props: IContractLabelProps) => {
	const rs = props.releaseState?.toLowerCase();
	return (
		<div className="contract-label-state">
			<span className={`contract-label release-state-label-${rs}`}>
				{rs === "commissioned" && <ClockIcon className="icon" />}
				{rs === "draft" && <FileIcon className="icon" />}
				{rs === "inactive" && <InactiveIcon className="icon" />}
				{rs === "inactive_deadline" && <TickIcon className="icon" />}
				{rs === "invalid" && <InvalidIcon className="icon" />}
				{rs === "released" && <TickIcon className="icon" />}
				{rs === "to_be_released" && <UserIcon className="icon" />}
				{props.t(`contract.release.state.${rs}`)}
			</span>
		</div>
	);
};

export const ContractStateLabel: FC<IContractLabelProps> = (props: IContractLabelProps) => {
	const css = props.contractState?.toString().toLowerCase();
	const deadline_suffix = props.releaseState === "INACTIVE_DEADLINE" ? "_inactive_deadline" : "";
	return (
		<div className="contract-label-state">
			<span className={`contract-label contract-state-label-${css}${deadline_suffix}`}>
				{css === "not_confirmed" && <FileIcon className="icon" />}
				{css === "being_reviewed" && <ClockIcon className="icon" />}
				{css === "confirmed" && <TickIcon className="icon" />}
				{css === "approved_until_deadline" && <TickIcon className="icon" />}
				{css === "expired" && <InactiveIcon className="icon" />}
				{css === "revoked" && <InactiveIcon className="icon" />}
				{css === undefined && <InactiveIcon className="icon" />}
				<>
					{props.contractState === "APPROVED_UNTIL_DEADLINE" && props.releaseState === "INACTIVE_DEADLINE"
						? props.t(`contract.contract.state.confirmed`)
						: props.t(`contract.contract.state.${css}`)}
				</>
			</span>
		</div>
	);
};

export interface IContractCategoryLabelProps {
	categoryName: string;
	iconName: string;
	iconSize: string;
}

export const ContractCategoryLabel: FC<IContractCategoryLabelProps> = ({ categoryName, iconName, iconSize }) => (
	<span className="category-label">
		<AntdIcon name={iconName} className="icon" size={iconSize} />
		{categoryName}
	</span>
);

export const ContractIntervalLabel: FC<IContractUIProps> = ({ contract: { interval }, t }) => (
	<span className="interval-label">
		<RepeatIcon className="icon" />
		{interval ? `${t("all")} ${interval} ${t("months")}` : t("infinite.interval")}
	</span>
);

interface IContractExpirationDateProps extends IBasicProps {
	contractState?: ContractState;
	releaseState?: string;
	expirationDate?: Date;
	infiniteInterval?: boolean;
	style?: string;
}
export const ContractExpirationDate: FC<IContractExpirationDateProps> = props => {
	const getExpirationDate = (): string => {
		if (props.expirationDate) {
			const prefix =
				props.contractState === "APPROVED_UNTIL_DEADLINE" || props.releaseState === "INACTIVE_DEADLINE"
					? `${props.t("contract.valid.deadline")} `
					: "";

			return prefix + getFormattedDate(props.expirationDate);
		} else {
			return "";
		}
	};

	const shouldShowExpirationDate = (): boolean =>
		!props.infiniteInterval || props.contractState === "APPROVED_UNTIL_DEADLINE";

	return (
		<>
			{props.expirationDate && shouldShowExpirationDate() && (
				<>
					{props.contractState !== "APPROVED_UNTIL_DEADLINE" && props.releaseState !== "INACTIVE_DEADLINE" && (
						<>{props.t("expiration.label")} </>
					)}
					{getExpirationDate()}
				</>
			)}
			{!shouldShowExpirationDate() && (
				<>
					{props.t("expiration.label")}
					<> </>
					{props.t("infinite.interval")}
				</>
			)}
		</>
	);
};

export const ContractExpired: FC<IContractExpirationDateProps> = ({ expirationDate, t }) => {
	const now = Date.now();
	/* eslint-disable */
	const exp: Date = new Date(expirationDate as Date);
	/* eslint-enable */
	const s = `${t("before")} ${Math.floor((now - exp.getTime()) / 86400000)} ${t("days")}`;
	return (
		<span className="expiration-label">
			<RepeatIcon className="icon" />
			{s}
		</span>
	);
};

export const ContractExpirationLabel: FC<IContractUIProps> = ({ contract, t, ...props }) => {
	const contractState = contract.contractState;
	return (
		<>
			{contractState === "NOT_CONFIRMED" && <></>}
			{contractState === "EXPIRED" && <ContractExpired expirationDate={contract.expirationDate} t={t} {...props} />}
			{contractState && ["CONFIRMED", "BEING_REVIEWED", "APPROVED_UNTIL_DEADLINE"].includes(contractState) && (
				<span className="expiration-label">
					<ContractExpirationDate
						releaseState={contract.releaseState}
						contractState={contract.contractState}
						expirationDate={contract.expirationDate}
						infiniteInterval={contract.infiniteInterval}
						style="expiration-label"
						t={t}
						{...props}
					/>
				</span>
			)}
		</>
	);
};
